import {
  CREATE_CHAT,
  GET_UNSEEN_MESSAGE,
  GET_CHATS,
  GET_CHAT,
  CHATS_START_LOADING,
  CHATS_END_LOADING,
  DELETE_CHAT,
  MESSAGE_SEEN,
  MESSAGE_SEEN_BY_PATIENT,
  GET_CHATS_BY_DOCTORS,
  EDIT_CHAT,
  CHATS_STARTS_LOADING,
  CHATS_ENDS_LOADING,
  MESSAGE_UNSEEN,
  MARK_CHAT_AS_DELETED_FOR_CLIENT,
  GET_UNSEEN_MESSAGE_COUNT,
} from "../actionTypes";

export const chatReducer = (
  state = {
    chatIsLoading: true,
    chat: [],
    unseenMessageCount: 0,
  },
  action,
) => {
  switch (action.type) {
    case CHATS_STARTS_LOADING:
      return { ...state, chatsIsLoading: true };
    case CHATS_ENDS_LOADING:
      return { ...state, chatsIsLoading: false };
    case GET_UNSEEN_MESSAGE_COUNT:
      return { ...state, unseenMessageCount: action.payload?.unseenCount ?? 0 };
    case GET_CHAT:
      return {
        ...state,
        chat: action.payload,
      };
    default:
      return state;
  }
};
const chatsReducers = (
  state = { chatsIsLoading: true, chats: [], chat: {}, unSeenMessage: [] },
  action,
) => {
  switch (action.type) {
    case CHATS_START_LOADING:
      return { ...state, chatsIsLoading: true };
    case CHATS_END_LOADING:
      return { ...state, chatsIsLoading: false };
    case GET_CHATS:
      return { ...state, chats: data };
    case GET_CHATS_BY_DOCTORS:
      const { data, numberOfPages } = action.payload;
      return {
        ...state,
        chats: action.payload.data,
        numberOfPages: action.payload.numberOfPages,
      };
    case CREATE_CHAT:
      return {
        ...state,
        chats: [action.payload, ...state.chats],
      };
    case EDIT_CHAT:
      return {
        ...state,
        chats: state.chats.map((chat) =>
          chat._id === action.payload._id ? action.payload : chat,
        ),
      };
    case GET_CHAT:
      return { ...state, chat: action.payload.data };
    case GET_UNSEEN_MESSAGE:
      return { ...state, unSeenMessage: action.payload };
    case DELETE_CHAT:
      return {
        ...state,
        chats: state.chats.filter((chat) => chat._id !== action.payload),
      };
    case MESSAGE_SEEN:
      return {
        ...state,
        chats: state.chats.map((chat) =>
          chat._id === action.payload ? { ...chat, seen: true } : chat,
        ),
      };
    case MESSAGE_SEEN_BY_PATIENT:
      return {
        ...state,
        chats: state.chats.map((chat) =>
          chat.about === action.payload ? { ...chat, seen: true } : chat,
        ),
      };
    case MESSAGE_UNSEEN:
      return {
        ...state,
        chats: state.chats.map((chat) =>
          chat._id === action.payload ? { ...chat, seen: false } : chat,
        ),
      };

    case MARK_CHAT_AS_DELETED_FOR_CLIENT:
      return {
        ...state,
        chats: state.chats.map((chat) =>
          chat._id === action.payload
            ? { ...chat, deletedForClient: true }
            : chat,
        ),
      };

    default:
      return state;
  }
};

export default chatsReducers;
