import {
  SEED_PATIENT,
  PATIENTS_END_LOADING,
  PATIENTS_LOADING,
  GET_PATIENTS,
  FILTER_PATIENTS,
  CREATE_PATIENT,
  EDIT_PATIENT,
  DELETE_PATIENT,
  BLOCK_PATIENT,
  GET_PATIENTS_BY_ETAB,
  GET_PATIENT,
  GET_PATIENTS_CHAT,
  PATIENT_ERROR,
  MARK_FILE_AS_DELETED_FOR_CLIENT,
  RESTORE_CLIENT_FILE,
  ONE_PATIENT_LOADING,
  ONE_PATIENT_END_LOADING,
} from "../actionTypes";

const patientsReducers = (
  state = {
    PatientsIsLoading: true,
    patients: [],
    patientsMap: {},
    patient: {}, // Ajouter ici pour stocker un seul patient
    chatsByPatient: [],
    patientError: "",
    patientsPage: [],
  },
  action,
) => {
  switch (action.type) {
    case PATIENTS_LOADING:
      return { ...state, PatientsIsLoading: true };
    case PATIENTS_END_LOADING:
      return { ...state, PatientsIsLoading: false };
    case ONE_PATIENT_LOADING:
      return { ...state, PatientsIsLoading: true }; // Début du chargement pour un patient spécifique
    case ONE_PATIENT_END_LOADING:
      return { ...state, PatientsIsLoading: false }; // Fin du chargement pour un patient spécifique
    case GET_PATIENT:
      return { ...state, patient: action.payload }; // Mettre à jour le patient sélectionné
    case GET_PATIENTS:
      return {
        ...state,
        patients: action.payload,
        patientsMap: action.payload.reduce(
          (acc, curr) => ({ ...acc, [curr._id]: curr }),
          {},
        ),
        patientsPage: action.payload,
      };
    case CREATE_PATIENT:
      const updatedPatients = [...state.patients, action.payload];
      return {
        ...state,
        patients: updatedPatients,
        patientsMap: updatedPatients.reduce(
          (acc, curr) => ({ ...acc, [curr._id]: curr }),
          {},
        ),
        patientsPage: updatedPatients,
      };
    case EDIT_PATIENT:
      const modifiedPatients = state.patients.map((pat) =>
        pat._id === action.payload._id ? action.payload : pat,
      );
      return {
        ...state,
        patients: modifiedPatients,
        patientsPage: modifiedPatients,
      };
    case DELETE_PATIENT:
      const remainingPatients = state.patients.filter(
        (pat) => pat._id !== action.payload,
      );
      return {
        ...state,
        patients: remainingPatients,
        patientsPage: remainingPatients,
      };
    case FILTER_PATIENTS:
      if (action.payload.filters === null) {
        return { ...state, patientsPage: state.patients };
      } else {
        const { data, numberOfPages } = action.payload;
        return { ...state, patientsPage: data, numberOfPages };
      }
    case MARK_FILE_AS_DELETED_FOR_CLIENT:
      return {
        ...state,
        patients: state.patients.map((patient) =>
          patient._id === action.payload
            ? { ...patient, deletedForClient: true }
            : patient,
        ),
      };
    case RESTORE_CLIENT_FILE:
      return {
        ...state,
        patients: state.patients.map((patient) =>
          patient._id === action.payload
            ? { ...patient, deletedForClient: false }
            : patient,
        ),
      };
    default:
      return state;
  }
};

export default patientsReducers;
