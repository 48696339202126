import {
    CREATE_PARENTAGE,
    EDIT_PARENTAGE,
    GET_PARENTAGE,
    GET_PARENTAGES,
    ONE_PARENTAGE_END_LOADING,
    ONE_PARENTAGE_LOADING,
    PARENTAGES_END_LOADING,
    PARENTAGES_LOADING,
    PARENTAGE_ERROR,
    SEED_PARENTAGE,
  } from "../actionTypes";
  
  // Initial state
  const initialState = {
    ParentagesIsLoading: false,  
    parentagesPage: [],          
    currentParentage: null,     
    oneParentageLoading: false,  
    error: null,                 
  };
  
  // Reducer function
  const parentagesReducer = (state = initialState, action) => {
    switch (action.type) {
      case PARENTAGES_LOADING:
        return {
          ...state,
          ParentagesIsLoading: true,
          error: null,
        };
      case PARENTAGES_END_LOADING:
        return {
          ...state,
          ParentagesIsLoading: false,
        };
      case ONE_PARENTAGE_LOADING:
        return {
          ...state,
          oneParentageLoading: true,
          error: null,
        };
      case ONE_PARENTAGE_END_LOADING:
        return {
          ...state,
          oneParentageLoading: false,
        };
        case SEED_PARENTAGE:
            return {
              ...state,
              parentagesPage: [...state.parentagesPage, action.payload], 
            };
      case GET_PARENTAGES:
        return {
          ...state,
          parentagesPage: action.payload.patients, // List of patients from getParentages
          ParentagesIsLoading: false,
        };
      case GET_PARENTAGE:
        return {
          ...state,
          currentParentage: action.payload,
          oneParentageLoading: false,
        };
        case CREATE_PARENTAGE:
            // Vérifiez si le patient existe déjà
            const exists = state.parentagesPage.some(parentage => parentage._id === action.payload._id);
            return {
                ...state,
                parentagesPage: exists ? state.parentagesPage : [...state.parentagesPage, action.payload],
                ParentagesIsLoading: false,
            };
        
      case EDIT_PARENTAGE:
        return {
          ...state,
          parentagesPage: state.parentagesPage.map((parentage) =>
            parentage._id === action.payload._id ? action.payload : parentage
          ),
          ParentagesIsLoading: false,
        };

      case PARENTAGE_ERROR:
        return {
          ...state,
          error: action.payload,
          ParentagesIsLoading: false,
          oneParentageLoading: false,
        };
      default:
        return state;
    }
  };
  
  export default parentagesReducer;
  