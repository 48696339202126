import { configureStore, applyMiddleware } from "@reduxjs/toolkit";
import auth from "./reducers/auth";
import usersReducers from "./reducers/users";
import chatsReducers from "./reducers/chats";
import doctorsReducers from "./reducers/doctors";
import agendasReducers from "./reducers/agendas";
import etablissementsReducers from "./reducers/etablissements";
import patientsReducers from "./reducers/patients";
import parentagesReducers from "./reducers/parentage";
import appointmentsReducers from "./reducers/appointments";
import settingsReducers from "./reducers/settings";
import resasReducers from "./reducers/resas";
import eventsReducers from "./reducers/events";
import smsReducers from "./reducers/sms";
import breakReducer from "./reducers/break";
import requestPatientReducers from "./reducers/requestPatient";
import impressionsReducers from "./reducers/historiqueImpression";
import prescriptionsReducer from "./reducers/prescription";
import thunk from "redux-thunk";

import { chatReducer } from "./reducers/chats";
import misc from "./reducers/misc";

export const store = configureStore(
  {
    reducer: {
      auth: auth,
      chat: chatReducer,
      users: usersReducers,
      chats: chatsReducers,
      doctors: doctorsReducers,
      etablissements: etablissementsReducers,
      agendas: agendasReducers,
      patients: patientsReducers,
      parentages: parentagesReducers,
      appointments: appointmentsReducers,
      resas: resasReducers,
      settings: settingsReducers,
      events: eventsReducers,
      sms: smsReducers,
      break: breakReducer,
      requestPatient: requestPatientReducers,
      misc: misc,
      impressions : impressionsReducers ,
      prescriptions : prescriptionsReducer , 
    },
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    //   immutableCheck: false,
    //   serializableCheck: false,
    // }
    // )
  },
  applyMiddleware(thunk),
);
