export const CREATE = "CREATE";
export const UPDATE = "UPDATE";
export const DELETE = "DELETE";
export const FETCH_ALL = "FETCH_ALL";
export const FETCH_POST = "FETCH_POST";
export const LIKE = "LIKE";
export const COMMENT = "COMMENT";
export const END_LOADING_POST = "END_LOADING_POST";
export const START_LOADING_POST = "START_LOADING_POST";
export const ONE_POST_LOADING = "ONE_POST_LOADING";
export const ONE_POST_END_LOADING = "ONE_POST_END_LOADING";
export const PUBLIC_WAITING = "PUBLIC_WAITING";
export const TOP_POST = "TOP_POST";
export const WAITING_POSTS = "WAITING_POSTS";
export const PUBLIC_POSTS = "PUBLIC_POSTS";
export const LOCK_CALENDAR_DATE = "LOCK_CALENDAR_DATE";
export const SET_SELECTED_ROW = "SET_SELECTED_ROW";

export const AUTH_LOADING = "AUTH_LOADING";
export const STOP_AUTH_LOADING = "STOP_AUTH_LOADING";
export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";

export const LIST_USERS = "LIST_USERS";
export const GET_USER = "GET_USER";
export const GET_ADMINS = "GET_ADMINS";
export const CREATE_USER = "CREATE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const END_LOADING_USER = "END_LOADING_USER";
export const START_LOADING_USER = "START_LOADING_USER";

export const CHATS_ENDS_LOADING = "CHATS_ENDS_LOADING";
export const CHATS_STARTS_LOADING = "CHATS_STARTS_LOADING";
export const GET_CHAT = "GET_CHAT";
export const GET_UNSEEN_MESSAGE_COUNT = "GET_UNSEEN_MESSAGE_COUNT";
export const GET_UNSEEN_MESSAGE = "GET_UNSEEN_MESSAGE";
export const CREATE_CHAT = "CREATE_CHAT";
export const EDIT_CHAT = "EDIT_CHAT";
export const GET_CHATS = "GET_CHATS";
export const GET_CHATS_BY_DOCTORS = "GET_CHAT_BY_DOCTORS";
export const CHATS_START_LOADING = "CHATS_START_LOADING";
export const CHATS_END_LOADING = "CHATS_END_LOADING";
export const END_LOADING_CHAT = "END_LOADING_CHAT";
export const START_LOADING_CHAT = "START_LOADING_CHAT";
export const DELETE_CHAT = "DELETE_CHAT";
export const MESSAGE_SEEN = "MESSAGE_SEEN";
export const MESSAGE_SEEN_BY_PATIENT = "MESSAGE_SEEN_BY_PATIENT";
export const MESSAGE_UNSEEN = "MESSAGE_UNSEEN";
export const MARK_CHAT_AS_DELETED_FOR_CLIENT =
  "MARK_CHAT_AS_DELETED_FOR_CLIENT";

export const TOGGLE_IS_ACTIVE = "TOGGLE_IS_ACTIVE";
export const DOCTORS_LOADING = "DOCTORS_LOADING";
export const DOCTORS_END_LOADING = "DOCTORS_END_LOADING";
export const ONE_DOCTOR_LOADING = "ONE_DOCTOR_LOADING";
export const ONE_DOCTOR_END_LOADING = "ONE_DOCTOR_END_LOADING";
export const GET_DOCTOR = "GET_DOCTOR";
export const GET_DOCTOR_BY_USER = "GET_DOCTOR_BY_USER";
export const GET_DOCTORS_BY_ETAB = "GET_DOCTORS_BY_ETAB";
export const GET_DOCTORS = "GET_DOCTORS";
export const CREATE_DOCTOR = "CREATE_DOCTOR";
export const EDIT_DOCTOR = "EDIT_DOCTOR";
export const DELETE_DOCTOR = "DELETE_DOCTOR";

export const PATIENTS_LOADING = "PATIENTS_LOADING";
export const PATIENTS_END_LOADING = "PATIENTS_END_LOADING";
export const ONE_PATIENT_LOADING = "ONE_PATIENT_LOADING";
export const ONE_PATIENT_END_LOADING = "ONE_PATIENT_END_LOADING";
export const GET_PATIENT = "GET_PATIENT";
export const GET_PATIENTS = "GET_PATIENTS";
export const FILTER_PATIENTS = "FILTER_PATIENTS";
export const PATIENT_CHAT_LOADING = "PATIENT_CHAT_LOADING";
export const GET_PATIENTS_CHAT = "GET_PATIENTS_CHAT";
export const PATIENT_END_LOADING = "PATIENT_END_LOADING";
export const GET_PATIENTS_BY_ETAB = "GET_PATIENTS_BY_ETAB";
export const CREATE_PATIENT = "CREATE_PATIENT";
export const EDIT_PATIENT = "EDIT_PATIENT";
export const DELETE_PATIENT = "DELETE_PATIENT";
export const BLOCK_PATIENT = "BLOCK_PATIENT";
export const SEED_PATIENT = "SEED_PATIENT";
export const PATIENT_ERROR = "PATIENT_ERROR";
export const MARK_FILE_AS_DELETED_FOR_CLIENT =
  "MARK_FILE_AS_DELETED_FOR_CLIENT";
export const RESTORE_CLIENT_FILE = "RESTORE_CLIENT_FILE";

export const PARENTAGES_LOADING = "PARENTAGES_LOADING";
export const PARENTAGES_END_LOADING = "PARENTAGES_END_LOADING";
export const ONE_PARENTAGE_LOADING = "ONE_PARENTAGE_LOADING";
export const ONE_PARENTAGE_END_LOADING = "ONE_PARENTAGE_END_LOADING";
export const GET_PARENTAGE = "GET_PARENTAGE";
export const GET_PARENTAGES = "GET_PARENTAGES";
export const PARENTAGE_END_LOADING = "PARENTAGE_END_LOADING";
export const CREATE_PARENTAGE = "CREATE_PARENTAGE";
export const EDIT_PARENTAGE = "EDIT_PARENTAGE";
export const DELETE_PARENTAGE = "DELETE_PARENTAGE";
export const PARENTAGE_ERROR = "PARENTAGE_ERROR";
export const SEED_PARENTAGE = "SEED_PARENTAGE";

export const ETABLISSEMENTS_LOADING = "ETABLISSEMENTS_LOADING";
export const ETABLISSEMENTS_END_LOADING = "ETABLISSEMENTS_END_LOADING";
export const ONE_ETABLISSEMENT_LOADING = "ONE_ETABLISSEMENT_LOADING";
export const ONE_ETABLISSEMENT_END_LOADING = "ONE_ETABLISSEMENT_END_LOADING";
export const GET_ETABLISSEMENT = "GET_ETABLISSEMENT";
export const GET_ETABLISSEMENTS = "GET_ETABLISSEMENTS";
export const CREATE_ETABLISSEMENT = "CREATE_ETABLISSEMENT";
export const EDIT_ETABLISSEMENT = "EDIT_ETABLISSEMENT";
export const DELETE_ETABLISSEMENT = "DELETE_ETABLISSEMENT";

export const AGENDAS_LOADING = "AGENDAS_LOADING";
export const AGENDAS_END_LOADING = "AGENDAS_END_LOADING";
export const ONE_AGENDA_LOADING = "ONE_AGENDA_LOADING";
export const ONE_AGENDA_END_LOADING = "ONE_AGENDA_END_LOADING";
export const GET_AGENDA = "GET_AGENDA";
export const GET_AGENDAS = "GET_AGENDAS";
export const GET_AGENDAS_BY_IDS = "GET_AGENDAS_BY_IDS";
export const CREATE_AGENDA = "CREATE_AGENDA";
export const EDIT_AGENDA = "EDIT_AGENDA";
export const DELETE_AGENDA = "DELETE_AGENDA";
export const CREATE_TEMPLATE = "CREATE_TEMPLATE";
export const UPDATE_TEMPLATE = "UPDATE_TEMPLATE";
export const GET_DOCTOR_DURATION = "GET_DOCTOR_DURATION";

/* #region  Appointments */
export const APPOINTMENTS_LOADING = "APPOINTMENTS_LOADING";
export const APPOINTMENTS_END_LOADING = "APPOINTMENTS_END_LOADING";
export const ONE_APPOINTMENT_LOADING = "ONE_APPOINTMENT_LOADING";
export const ONE_APPOINTMENT_END_LOADING = "ONE_APPOINTMENT_END_LOADING";
export const APPOINTMENTS_ERROR = "APPOINTMENTS_ERROR";
export const GET_APPOINTMENTS_BY_MONTH = "GET_APPOINTMENTS_BY_MONTH";
export const GET_APPOINTMENT = "GET_APPOINTMENT";
export const GET_APPOINTMENTS = "GET_APPOINTMENTS";
export const GET_MY_APPOINTMENTS = "GET_MY_APPOINTMENTS";
export const FILTER_APPOINTMENTS = "FILTER_APPOINTMENTS";
export const FILTER_APPOINTMENTS_BY_PATIENT_ID =
  "FILTER_APPOINTMENTS_BY_PATIENT_ID";
export const FILTER_APPOINTMENTS_PRINT = "FILTER_APPOINTMENTS_PRINT";
export const CREATE_APPOINTMENT = "CREATE_APPOINTMENT";
export const EDIT_APPOINTMENT = "EDIT_APPOINTMENT";
export const CANCEL_APPOINTMENT = "CANCEL_APPOINTMENT";
export const CANCEL_MULTIPLE_APPOINTMENT = "CANCEL_MULTIPLE_APPOINTMENT";
export const BLOCK_APPOINTMENT = "BLOCK_APPOINTMENT";
export const CHANGE_STATUS_APPOINTMENT = "CHANGE_STATUS_APPOINTMENT";
export const ADD_MANY_APPOINTMENTS = "ADD_MANY_APPOINTMENTS";
export const SEED_APPOINTMENTS = "SEED_APPOINTMENTS";
export const TODAY_APPOINTMENTS = "TODAY_APPOINTMENTS";
export const DELETE_APPOINTMENTS = "DELETE_APPOINTMENTS";
export const SET_PAGINATION_COUNT = "SET_PAGINATION_COUNT";
/* #endregion */

export const RESA_LOADING = "RESA_LOADING";
export const RESA_END_LOADING = "RESA_END_LOADING";
export const GET_RESA = "GET_RESA";
export const GET_RESAS = "GET_RESAS";
export const GET_RESAS_BY_MONTH = "GET_RESAS_BY_MONTH";
export const GET_RESAS_BY_AGENDAS = "GET_RESAS_BY_AGENDAS";
export const GET_RESAS_BY_DOCTORID = "GET_RESAS_BY_DOCTORID";
export const GET_RESAS_BY_ETABLISSEMENTID = "GET_RESAS_BY_ETABLISSEMENTID";
export const CREATE_RESA = "CREATE_RESA";
export const EDIT_RESA = "EDIT_RESA";
export const SOCKET_EDIT_RESA = "SOCKET_EDIT_RESA";
export const DELETE_RESA = "DELETE_RESA";
export const CHANGE_STATUS_RESA = "CHANGE_STATUS_RESA";

/* #region  Reservation */
export const RESA_LOADING_VAD = "RESA_LOADING_VAD";
export const RESA_END_LOADING_VAD = "RESA_END_LOADING_VAD";
export const GET_RESA_VAD = "GET_RESA_VAD";
export const GET_RESAS_VAD = "GET_RESAS_VAD";
export const GET_RESAS_BY_MONTH_VAD = "GET_RESAS_BY_MONTH_VAD";
export const GET_RESAS_BY_AGENDAS_VAD = "GET_RESAS_BY_AGENDAS_VAD";
export const GET_RESAS_BY_DOCTORID_VAD = "GET_RESAS_BY_DOCTORID_VAD";
export const GET_RESAS_BY_ETABLISSEMENTID_VAD =
  "GET_RESAS_BY_ETABLISSEMENTID_VAD";
export const CREATE_RESA_VAD = "CREATE_RESA_VAD";
export const EDIT_RESA_VAD = "EDIT_RESA_VAD";
export const EDIT_MANY_RESA = "EDIT_MANY_RESA";
export const DELETE_RESA_VAD = "DELETE_RESA_VAD";
export const CHANGE_STATUS_RESA_VAD = "CHANGE_STATUS_RESA_VAD";

/* #endregion */
export const SETTINGS_LOADING = "SETTINGS_LOADING";
export const SETTINGS_END_LOADING = "SETTINGS_END_LOADING";
export const SEED_SETTINGS = "SEED_SETTINGS";
export const GET_SETTINGS = "GET_SETTINGS";
export const CREATE_SETTING = "CREATE_SETTING";
export const UPDATE_SETTING = "UPDATE_SETTING";
export const DELETE_SETTING = "DELETE_SETTING";

export const EVENTS_LOADING = "FILTER_LOADING";
export const FILTER_EVENTS = "FILTER_EVENTS";
export const EVENTS_END_LOADING = "FILTER_END_LOADING";

export const SMS_GET_TOMORROW = "SMS_GET_TOMORROW";
export const SMS_LOADING = "SMS_LOADING";
export const SMS_END_LOADING = "SMS_END_LOADING";
export const SMS_SEND = "SMS_SEND";
export const SMS_LOG = "SMS_LOG";

/* #region  Break */
//! ------------- BREAK -----------------
export const LOADING_BREAK = "LOADING_BREAK";
export const END_LOADING_BREAK = "END_LOADING_BREAK";
export const SET_BREAK_FILTERS = "SET_BREAK_FILTERS";
export const SET_BREAK = "SET_BREAK";
export const SET_BREAKS = "SET_BREAKS";
export const ADD_BREAK = "ADD_BREAK";
export const UPDATE_BREAK = "UPDATE_BREAK";
export const DELETE_BREAK = "DELETE_BREAK";

/* #endregion */

/* #region  request patient */
//! ------------- Request PAtient -----------------
export const START_LOADING_REQUEST = "START_LOADING_REQUEST";
export const END_LOADING_REQUEST = "END_LOADING_REQUEST";
export const GET_REQUEST = "GET_REQUEST";
export const UPDATE_REQUEST = "UPDATE_REQUEST";
export const DELETE_REQUEST = "DELETE_REQUEST";

/* #endregion */

//region HistoriqueImpression
export const GET_IMPRESSIONS = "GET_IMPRESSIONS";
export const IMPRESSIONS_LOADING = "IMPRESSIONS_LOADING";
export const IMPRESSIONS_END_LOADING = "IMPRESSIONS_END_LOADING";
export const IMPRESSION_ERROR = "IMPRESSION_ERROR";

// Prescription Action Types
export const GET_PRESCRIPTIONS = "GET_PRESCRIPTIONS";
export const ADD_PRESCRIPTION = "ADD_PRESCRIPTION";
export const UPDATE_PRESCRIPTION = "UPDATE_PRESCRIPTION";
export const DELETE_PRESCRIPTION = "DELETE_PRESCRIPTION";
export const PRESCRIPTION_ERROR = "PRESCRIPTION_ERROR";
export const PRESCRIPTIONS_LOADING = "PRESCRIPTIONS_LOADING";
